import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Footer from "./Footer";

function Discovery() {
  const params = useParams();
  const themeID = params.themeID;

  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  const day = String(currentDate.getDate()).padStart(2, "0");
  const formattedDate = `${day}-${month}-${year}`;

  const [discovery, setDiscovery] = useState();
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch(`/api/themes/${themeID}/discoveries/next`)
      .then((response) => {
        if (!response.ok) {
          if (response.status === 404) {
            throw new Error("Няма открито съдържание за деня");
          } else if (response.status === 401) {
            throw new Error("Нямате достъп до това съдържание");
          }

          throw new Error("Неочаквана грешка при заявка към системата");
        }

        return response.json();
      })
      .then((data) => {
        setDiscovery(data);
      })
      .catch((err) => {
        setError(err?.message || "Неочаквана грешка");
      });
  }, [themeID]);

  return (
    <section
      style={{
        minHeight: "100vh",
        flexDirection: "column",
        gap: "48px",
        marginTop: "0px",
      }}
    >
      <nav style={{ paddingTop: "8px" }}>
        <div className="nav-block">
          <a href="/" target="_blank">
            <img src="/logo-with-heading.png" className="nav-logo" />
          </a>
        </div>
      </nav>

      <div
        className="section-block form-section-block"
        style={{
          marginTop: "auto",
          paddingBottom: "0px",
          gap: "48px",
          maxWidth: "448px",
          textAlign: "center",
        }}
      >
        {error && (
          <span style={{ color: "#ff0000", fontSize: "16px" }}>{error}</span>
        )}

        {discovery && !error && (
          <>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "12px" }}
            >
              <h2>{getHeaderFromType(discovery.type, setError)}</h2>
              <h6 style={{ fontSize: "12px" }}>{formattedDate}</h6>
            </div>

            {discovery?.warning && (
              <p
                style={{
                  fontSize: "16px",
                  fontFamily: '"Prata", sans-serif',
                  color: "#DC143C",
                }}
              >
                {discovery.warning}
              </p>
            )}

            <p
              style={{
                fontSize: "20px",
                fontFamily: '"Prata", sans-serif',
              }}
            >
              {discovery.content}
            </p>
            <h6 style={{ fontSize: "14px" }}>
              {getSubheadingFromType(discovery.type, setError)}
            </h6>
          </>
        )}
      </div>
      <div style={{ marginTop: "auto" }}>
        <Footer />
      </div>
    </section>
  );
}

function getHeaderFromType(discoveryType, setError) {
  switch (discoveryType) {
    case "joke":
      return "Виц на Деня";
    case "fortune":
      return "Късмет на Деня";
    case "fact":
      return "Факт на Деня";
    default:
      setError("Непознат тип съдържание");
  }
}

function getSubheadingFromType(discoveryType, setError) {
  switch (discoveryType) {
    case "joke":
      return "Сканирай QR кода и утре за нов виц!";
    case "fortune":
      return "Сканирай QR кода и утре за ново късметче!";
    case "fact":
      return "Сканирай QR кода и утре за нов факт!";
    default:
      setError("Непознат тип съдържание");
  }
}

export default Discovery;
