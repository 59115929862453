import React, { useEffect } from "react";
import Footer from "./Footer";
import { useForm } from "react-hook-form";
import { Asterisk, Lock } from "phosphor-react";
import { RotatingLines } from "react-loader-spinner";
import { useParams } from "react-router-dom";

function Access() {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    criteriaMode: "all",
  });

  const [formSubmitError, setFormSubmitError] = React.useState(null);
  const [formSuccessMessage, setFormSuccessMessage] = React.useState(null);

  const params = useParams();
  const qrCodeID = params.qrCodeID;

  useEffect(() => {
    fetch(`/api/qr-codes/${qrCodeID}/config`, {
      method: "GET",
    })
      .then((resp) => {
        if (!resp.ok) {
          if (resp.status === 401) {
            // user is unauthorized, needs to input new access code
            return;
          }

          throw new Error(
            "Неочаквана грешка при зареждане на страницата. Моля, опитайте отново или се свържете с нас за съдействие."
          );
        }

        // user is authorized, redirect to content page
        window.location.pathname = `/api/qr-codes/${qrCodeID}/content`;
      })
      .catch((err) => {
        setFormSubmitError(err?.message || err || "Неочаквана грешка.");
      });
  });

  const onSubmit = (data) => {
    const reqBody = {
      access_key: data.form_access_key,
    };

    return fetch("/api/users/tokens", {
      method: "POST",
      body: JSON.stringify(reqBody),
    })
      .then((resp) => {
        if (!resp.ok) {
          if (resp.status === 401) {
            throw new Error("Невалиден код за достъп.");
          }

          throw new Error(
            "Неочаквана грешка при изпращане на заявката. Моля, опитайте отново или се свържете с нас за съдействие."
          );
        }

        setFormSuccessMessage("Успешно отваряне на подаръка!");
        window.location.pathname = `/api/qr-codes/${qrCodeID}/content`;
      })
      .catch((err) => {
        setFormSubmitError(err?.message || err || "Неочаквана грешка.");
      });
  };

  return (
    <>
      <section
        style={{
          minHeight: "100vh",
          flexDirection: "column",
          gap: "48px",
          marginTop: "0px",
        }}
      >
        <nav style={{ paddingTop: "8px" }}>
          <div className="nav-block">
            <a href="/" target="_blank">
              <img src="/logo-with-heading.png" className="nav-logo" />
            </a>
          </div>
        </nav>
        <div
          className="section-block form-section-block"
          style={{
            marginTop: "auto",
            paddingBottom: "0px",
            gap: "12px",
            maxWidth: "448px",
          }}
        >
          <p className="section-subtext">
            Кодът за достъп се намира в кутията, която сте получили заедно с
            подаръка ви. Моля, въведете този код, за да отворите съдържанието на
            подаръка.
          </p>

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-row width-100">
              <div className="form-row-col width-100">
                <label htmlFor="form_access_key" className="vertical-alignment">
                  <Lock size={16} weight="bold" className="neutral-color" /> Код
                  за достъп{" "}
                  <Asterisk
                    size={16}
                    weight="bold"
                    className="form-label-asterisk"
                  />
                </label>
                {errors && errors.form_access_key && (
                  <span className="form-error-message">
                    {errors.form_access_key.message}
                  </span>
                )}
                <input
                  type="text"
                  id="form_access_key"
                  maxLength={64}
                  disabled={isSubmitting}
                  {...register("form_access_key", {
                    required: "Моля, въведете код за достъп.",
                  })}
                  style={{ width: "auto" }}
                />
              </div>
            </div>
            <div className="form-row width-100" style={{ paddingTop: "0px" }}>
              <div className="form-row-col width-100">
                {formSubmitError && (
                  <>
                    <span className="form-error-message">
                      {formSubmitError}
                    </span>
                    <br />
                  </>
                )}

                {formSuccessMessage && (
                  <>
                    <span className="form-success-message">
                      {formSuccessMessage}
                    </span>
                    <br />
                  </>
                )}

                {isSubmitting ? (
                  <div style={{ alignSelf: "center" }}>
                    <RotatingLines
                      visible={true}
                      height="48"
                      width="48"
                      strokeColor="#fff75e"
                      strokeWidth="5"
                      animationDuration="1"
                    />
                  </div>
                ) : (
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    onClick={() => {
                      setFormSubmitError(null);
                      setFormSuccessMessage(null);
                    }}
                    className="form-submit-button"
                  >
                    Отвори
                  </button>
                )}
              </div>
            </div>
          </form>
        </div>
        <div style={{ marginTop: "auto" }}>
          <Footer />
        </div>
      </section>
    </>
  );
}

export default Access;
